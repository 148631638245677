import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from './interceptor';
import { getAcquirerId } from '../../components/auth/UserData';

export const getTransactions = createAsyncThunk(
  'transactions/get',
  async ({ dateFrom, dateTo, projectId, limit }) => {
    const response = await api.get(
      `/portalapi/transaction?projectId=${projectId}&dateFrom=${dateFrom}&dateTo=${dateTo}&limit=${limit}`
    );

    let dataResponse = response.data;
    dataResponse.sort((a, b) => {
      const firstDate = new Date(
        a.timestamp.split(' ')[0].split('-').reverse().join('-') +
          'T' +
          a.timestamp.split(' ')[1]
      );
      const secondDate = new Date(
        b.timestamp.split(' ')[0].split('-').reverse().join('-') +
          'T' +
          b.timestamp.split(' ')[1]
      );
      return secondDate - firstDate;
    });
    for (let i = 0; i < dataResponse.length; i++) {
      const sender = dataResponse[i].sender;
      const recipient = dataResponse[i].recipient;
      recipient.amount = recipient.amount ? recipient.amount.toFixed(2) : '';

      const recipientAmount = recipient.amount;
      const currencyCode = recipient.currencyCode ?? '';
      const recipientAmountCode = `${recipientAmount} ${currencyCode}`;
      const recipientFullName = `${recipient.name ?? ''} ${
        recipient.lastName ?? ''
      }`;
      const recipientAccountNumber =
        recipient.accountNumber !== null
          ? recipient.accountNumber.replace(/;/g, '')
          : '';
      const recipientAddress = recipient.address;
      const recipientAddressLine = recipientAddress.line || '';
      const recipientCountryCode = recipient.countryCode;
      const recipientCurrencyCode = recipient.currencyCode;
      const recipientBankCode = recipient.bankCode;
      const recipientBankCodeType = recipient.bankCodeType;
      const recipientBankName = recipient.bankName;
      const recipientAccountNumberType = recipient.accountNumberType;
      const recipientTaxId = recipient.taxId;
      const senderFullName = `${sender.name ?? ''} ${sender.lastName ?? ''}`;
      const senderAddress = sender.address;
      const senderAddressLine =
        (senderAddress.line || '') +
        ' ' +
        (senderAddress.city || '') +
        ' ' +
        (senderAddress.state || '');
      const senderTaxId = sender.taxId;
      const settlementAmount = dataResponse[i].settlementAmount ?? '';
      const settlementCurrencyCode =
        dataResponse[i].settlementCurrencyCode ?? '';

      dataResponse[i].recipientCurrencyCode = recipient.currencyCode;
      dataResponse[i].recipient.amount = recipientAmount ?? '';
      dataResponse[i].recipientAmount = recipientAmount ?? '';
      dataResponse[i].recipientAmountCode = recipientAmountCode;
      dataResponse[
        i
      ].settledAmountCode = `${settlementAmount} ${settlementCurrencyCode}`;
      dataResponse[i].nameSender = sender.name;
      dataResponse[i].lastNameSender = sender.lastName ?? '';
      dataResponse[i].fullNameSender = senderFullName ?? '';
      dataResponse[i].addressCitySender = senderAddress.city;
      dataResponse[i].addressCountrySender = senderAddress.country;
      dataResponse[i].addressLineSender = senderAddressLine;
      dataResponse[i].senderAccountNumberSender = sender.senderAccountNumber;
      dataResponse[i].taxIdSender = senderTaxId;
      dataResponse[i].typeSender = sender.type;
      dataResponse[i].taxIdRecipient = recipientTaxId;
      dataResponse[i].firstNameRecipient = recipient.name;
      dataResponse[i].lastNameRecipient = recipient.lastName ?? '';
      dataResponse[i].fullNameRecipient = recipientFullName ?? '';
      dataResponse[i].bankCodeRecipient = recipientBankCode;
      dataResponse[i].bankCodeTypeRecipient = recipientBankCodeType;
      dataResponse[i].bankNameRecipient = recipientBankName;
      dataResponse[i].accountNumberTypeRecipient = recipientAccountNumberType;
      dataResponse[i].accountNumberRecipient = recipientAccountNumber;
      dataResponse[i].paymentTypeRecipient = dataResponse[i].paymentType;
      dataResponse[i].accountNameRecipient = recipient.accountName;
      dataResponse[i].countryCodeRecipient = recipientCountryCode;
      dataResponse[i].currencyCodeRecipient = recipientCurrencyCode;
      dataResponse[i].addressCountryRecipient = recipientAddress.country;
      dataResponse[i].addressStateRecipient = recipientAddress.state;
      dataResponse[i].addressCityRecipient = recipientAddress.city;
      dataResponse[i].addressPostalCodeRecipient = recipientAddress.postalCode;
      dataResponse[i].addressLineRecipient = recipientAddressLine;

      delete dataResponse[i].sender;
      delete dataResponse[i].recipient;
    }

    return dataResponse;
  }
);

export const getTotalTransactions = createAsyncThunk(
  'transactions/get',
  async ({ dateFrom, dateTo, projectId, limit }) => {
    const response = await api.get(
      `/portalapi/transaction?projectId=${projectId}&dateFrom=${dateFrom}&dateTo=${dateTo}&limit=${limit}`
    );
    const responseData = response.data;
    const quantityTransactions =
      responseData.length > 0 ? responseData.length : 0;
    let approvedTransactions = 0;
    let rejectedTransactions = 0;
    let amountbyCode = {};
    let currencies = [];
    for (let i = 0; i < responseData.length; i++) {
      if (responseData[i].status === 'SENT') {
        approvedTransactions++;
      } else if (responseData[i].status === 'REJECTED') {
        rejectedTransactions++;
      }
    }

    for (let i = 0; i < responseData.length; i++) {
      const { settlementCurrencyCode, settlementAmount } = responseData[i];

      currencies.push({
        code:
          settlementCurrencyCode === '' ||
          settlementCurrencyCode === 'null' ||
          settlementCurrencyCode === null ||
          settlementCurrencyCode !== 'EUR'
            ? 'USD'
            : settlementCurrencyCode,
        amount:
          responseData[i].status === 'SENT' && isNumber(settlementAmount)
            ? settlementAmount
            : 0,
      });
    }

    currencies.forEach(item => {
      if (!amountbyCode[item.code]) {
        amountbyCode[item.code] = {
          amounts: [],
          total: 0,
        };
      }

      amountbyCode[item.code].amounts.push(item.amount);
      amountbyCode[item.code].total += item.amount;
    });

    return {
      total_quantity_transactions: quantityTransactions,
      total_approved_transaction: approvedTransactions,
      total_rejected_transactions: rejectedTransactions,
      total_amount_by_currency_code: amountbyCode,
    };
  }
);

export const formatCurrency = (amount, code) => {
  if (code === 'null' || code === null || code === '') {
    return amount;
  } else {
    const options = {
      style: 'currency',
      currency: code,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    return new Intl.NumberFormat('en-US', options).format(amount);
  }
};

export const isNumber = value => {
  return typeof value === 'number';
};
