import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#eff7f7',
        color: 'black',
      },
      '::-webkit-scrollbar': {
        width: '0.25rem',
        backgroundColor: 'white',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#982549',
      },
      '*': {
        scrollbarColor: '#982549 white',
      },
      'input::placeholder': {
        color: 'gray.500',
      },
    },
  },

  colors: {
    brand: {
      900: '#000000',
      800: '#1a1a1a',
      700: '#333333',
    },
  },
  components: {
    Card: {
      baseStyle: {
        container: {
          bg: 'brand.900',
        },
        body: {
          bg: '#FFFFFF',
        },
      },
    },
    Drawer: {
      baseStyle: {
        container: {
          bg: '#FFFFFF',
        },
        body: {
          bg: '#FFFFFF',
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 'ligth',
        fontFamily: `'Sans', sofia-sans`,
        borderRadius: 'md',
      },
      variants: {
        modern: {
          bg: '#982549',
          color: 'white',
          _hover: {
            bg: 'white',
            color: '#982549',
            boxShadow: 'md',
            border: '1px solid #982549',
          },
          _active: {
            bg: '#7dcbe1',
            color: 'white',
            transform: 'scale(0.98)',
          },
          _focus: {
            boxShadow: 'outline',
          },
        },
      },
      defaultProps: {
        variant: 'modern',
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderRadius: 'md',
          border: '1px solid green',
          _focus: {
            borderColor: '#982549',
            boxShadow: '0 0 0 1px #982549',
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: '#982549',
            _hover: {
              borderColor: '#982549',
            },
            _focus: {
              borderColor: '#982549',
              boxShadow: '0 0 0 1px #982549',
            },
          },
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'outline',
        focusBorderColor: '#982549',
      },
    },
    NumberInput: {
      baseStyle: {
        field: {
          borderRadius: 'md',
          border: '1px solid green',
          _focus: {
            borderColor: '#982549',
            boxShadow: '0 0 0 1px #982549',
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: '#982549',
            _hover: {
              borderColor: '#982549',
            },
            _focus: {
              borderColor: '#982549',
              boxShadow: '0 0 0 1px #982549',
            },
          },
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'outline',
        focusBorderColor: '#982549',
      },
    },
    Select: {
      baseStyle: {
        field: {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
          border: '1px solid black',
          _hover: {
            boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        _hover: {
          borderColor: 'gray',
        },
        _focus: {
          borderColor: '#982549',
          boxShadow: '0 0 0 1px #982549',
        },
        _focusVisible: {
          borderColor: 'red.500',
          boxShadow: '0 0 0 1px red.500',
        },
      },
      variants: {
        outline: {
          border: '1px solid',
          borderColor: 'gray.300',
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'outline',
      },
    },
    Radio: {
      baseStyle: {
        control: {
          bg: 'white',
          borderColor: 'gray.400',
          _checked: {
            bg: '#982549',
            borderColor: '#982549',
            _before: {
              content: '""',
              display: 'block',
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              bg: 'white',
            },
          },
          _hover: {
            bg: 'gray.300',
            borderColor: 'gray.500',
          },
          _focus: {
            boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)',
          },
        },
        label: {
          color: 'gray.700',
          _checked: {
            color: 'blue.500',
          },
          _hover: {
            color: 'gray.800',
          },
        },
      },
    },
  },
});

export default customTheme;
