import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Text,
  Input,
  Button,
  TableContainer,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Spacer,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerFooter,
  DrawerOverlay,
  Divider,
  Select as SelectTwo,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import {
  getProducts,
  getProduct,
  registerProduct,
  disableProduct,
  enableProduct,
  updateProduct,
} from '../../../../redux/action/products';
import { useToast } from '@chakra-ui/react';
import { DeleteIcon, SearchIcon, EditIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../../../assets/custom/colors';
import { getMerchants } from '../../../../redux/action/merchant';
import Select from 'react-select';
import {
  getProjectByAcquirer,
  getProjectById,
} from '../../../../redux/action/projects';

const DataProcess = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      productCode: '',
      description: '',
      merchantId: '',
      projectId: '',
    },
  });

  const {
    handleSubmit: handleSubmitFormFee,
    register: registerFormFee,
    reset: resetFormFee,
    formState: { errors: errorsFormFee, isSubmitting: isSubmittingFormFee },
  } = useForm({});

  const {
    handleSubmit: handleSubmitFormLimit,
    register: registerFormLimit,
    reset: resetFormLimit,
    formState: { errors: errorsFormLimit, isSubmitting: isSubmittingFormLimit },
  } = useForm({});

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editDescription, setEditDescription] = useState();
  const [isView, setIsView] = useState(false);
  const [addNew, setaddNew] = useState(false);
  const [addFees, setAddFees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [countries, setCountries] = useState([]);
  const [opCountries, setOpCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [opCurrencies, setOpCurrencies] = useState([]);
  const [elementCurrencies, setElementCurrencies] = useState([]);
  const [opSetElement, setOpSetElement] = useState([]);
  const [addLimits, setAddLimits] = useState([]);
  const [addNewFee, setaddNewFee] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const [addNewLimit, setAddNewLimit] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [merchantId, setMerchantId] = useState([]);

  const enableButtonTemplate = rowData => {
    return (
      <Flex justifyContent={'start'} ps={12}>
        <Switch
          isChecked={rowData?.status === 'ENABLED' ? true : false}
          onChange={() => handleEnable(rowData)}
        />
      </Flex>
    );
  };
  const ViewButtonTemplate = data => {
    return (
      <Flex justifyContent={'start'} ps={10}>
        <SearchIcon
          cursor={'pointer'}
          onClick={() => handleOpenDetails(data)}
        />
      </Flex>
    );
  };
  const limitTemplate = rowData => {
    return <Text>{t(`limits.${rowData.method}`)}</Text>;
  };

  const feesTemplate = rowData => {
    return <Text>{t(`fees.${rowData.method}`)}</Text>;
  };

  const visibleTemplate = rowData => {
    return <Text>{t(`fees.${rowData.visible}`)}</Text>;
  };

  const targetTemplate = rowData => {
    return <Text>{t(`fees.${rowData.target}`)}</Text>;
  };

  const EditButtonTemplate = rowData => {
    return (
      <EditIcon
        marginLeft={5}
        cursor={'pointer'}
        onClick={() => handleEdit(rowData)}
      />
    );
  };

  const deleteLimitAndFeeButtonTemplate = (rowData, onOpenModalDel) => {
    return (
      <DeleteIcon
        marginLeft={4}
        cursor="pointer"
        onClick={() => onOpenModalDel(rowData)}
      />
    );
  };

  useEffect(() => {
    const products = async () => {
      try {
        const data = await dispatch(getProducts()).unwrap();
        setProducts(data);
      } catch (error) {}
    };
    products();
  }, [dispatch]);

  useEffect(() => {
    const projects = async () => {
      try {
        const data = await dispatch(getProjectByAcquirer('')).unwrap();
        setProjects(data);
      } catch (error) {}
    };
    projects();
  }, [dispatch]);

  useEffect(() => {
    const merchants = async () => {
      try {
        const data = await dispatch(getMerchants()).unwrap();
        setMerchants(data);
      } catch (error) {}
    };
    merchants();
  }, [dispatch]);

  const columns = [
    { field: 'productCode', header: 'Code', sortable: true },
    { field: 'description', header: 'Description', sortable: false },
    { field: 'projectName', header: 'projectSend', sortable: false },
    {
      field: 'iconEnable',
      color: 'black',
      header: 'Disable/Enable',
      sortable: false,
      body: rowData => enableButtonTemplate(rowData),
    },
    {
      field: 'iconView',
      color: 'black',
      header: 'Details',
      sortable: false,
      body: rowData => ViewButtonTemplate(rowData),
    },
    {
      field: 'iconEdit',
      color: 'black',
      header: 'iconEdit',
      sortable: false,
      body: EditButtonTemplate,
    },
  ];

  const columnsFees = [
    { field: 'description', header: 'Description', sortable: false },
    {
      field: 'method',
      header: 'Method',
      sortable: true,
      body: rowData => feesTemplate(rowData),
    },
    { field: 'value', header: 'Filter Value', sortable: false },
    {
      field: 'target',
      header: 'Target',
      sortable: false,
      body: rowData => targetTemplate(rowData),
    },
    { field: 'priority', header: 'Priority', sortable: false },
    {
      field: 'visible',
      header: 'Visible',
      sortable: false,
      body: rowData => visibleTemplate(rowData),
    },
    {
      field: 'iconDelete',
      color: 'black',
      header: 'Disable/Enable',
      sortable: false,
      body: rowData => deleteLimitAndFeeButtonTemplate(rowData, handleDelFee),
    },
  ];

  const columnsLimits = [
    { field: 'description', header: 'Description', sortable: true },
    {
      field: 'method',
      header: 'Method',
      sortable: true,
      body: rowData => limitTemplate(rowData),
    },
    { field: 'value', header: 'Value/Quantity', sortable: false },
    { field: 'bufferTime', header: 'Period(secs)', sortable: false },
    {
      field: 'iconDelete',
      color: 'black',
      header: 'Disable/Enable',
      sortable: false,
      body: rowData => deleteLimitAndFeeButtonTemplate(rowData, handleDelLimit),
    },
  ];

  const handleDelFee = e => {
    let newFees = addFees.filter(x => x.index !== e.index);
    setAddFees(newFees);
  };

  const handleDelLimit = e => {
    let newLimits = addLimits.filter(x => x.index !== e.index);
    setAddLimits(newLimits);
  };

  const handleProject = e => {
    const p = projects.find(f => f.id === e.target.value);
    const listCountries = [];
    const listCurrencies = [];
    const listElementCurrencies = [];
    p.countries.map(m => {
      const countries = {
        label: m,
        value: m,
      };
      return listCountries.push(countries);
    });
    setOpCountries(listCountries);
    p.currencies.map(m => {
      const currencies = {
        label: m,
        value: m,
      };
      return listCurrencies.push(currencies);
    });
    setOpCurrencies(listCurrencies);
    p.settlementCurrencies.map(m => {
      const settlementCurrencies = {
        label: m,
        value: m,
      };
      return listElementCurrencies.push(settlementCurrencies);
    });
    setOpSetElement(listElementCurrencies);
  };

  async function onSubmitFee(values) {
    setAddFees(prevAddFees => [...prevAddFees, values]);
    resetFormFee({});
    setaddNewFee(false);
  }

  async function onSubmitLimit(values) {
    setAddLimits(prevAddLimits => [...prevAddLimits, values]);
    resetFormLimit({});
    setAddNewLimit(false);
  }

  async function onSubmit(values) {
    if (
      countries.length === 0 ||
      currencies.length === 0 ||
      elementCurrencies.length === 0
    ) {
      toast({
        title: t('toast.invalidForm'),
        position: 'top',
        description: t('toast.invalidFormDesc'),
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
    } else if (
      countries.length >= 1 &&
      currencies.length >= 1 &&
      elementCurrencies.length >= 1
    ) {
      values.fees = addFees;
      values.limits = addLimits;
      let data = {
        ...values,
        destinationCountry: countries.map(m => m.value),
        settlementCurrencies: elementCurrencies.map(m => m.value),
        destinationCurrencies: currencies.map(m => m.value),
      };
      let resp = await dispatch(registerProduct(data)).unwrap();
      if (resp?.status === 200) {
        toast({
          title: t(`toast.productCreateTitle`),
          position: 'top',
          description: t(`toast.productCreateDesc`),
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      }
      setTimeout(async () => {
        const get = await dispatch(getProducts()).unwrap();
        setProducts(get);
      }, 2000);
      setaddNew(false);
    }
  }

  const handleEdit = async data => {
    const prod = products.find(p => p.id === data.id);
    const project = await dispatch(getProjectById(data.projectId)).unwrap();
    const listCountries = [];
    const listCurrencies = [];
    const listElementCurrencies = [];
    setEditDescription(prod.description);
    project?.countries.map(m => {
      const countries = {
        label: m,
        value: m,
      };
      return listCountries.push(countries);
    });
    setOpCountries(listCountries);
    project?.currencies.map(m => {
      const currencies = {
        label: m,
        value: m,
      };
      return listCurrencies.push(currencies);
    });
    setOpCurrencies(listCurrencies);
    project?.settlementCurrencies.map(m => {
      const settlementCurrencies = {
        label: m,
        value: m,
      };
      return listElementCurrencies.push(settlementCurrencies);
    });
    setOpSetElement(listElementCurrencies);
    reset({
      productCode: prod.productCode,
      projectId: prod.projectName,
      settlementCurrencyCode: prod.settlementCurrencyCode,
      description: prod.description,
      provider: prod.providerId,
      merchantName: prod.merchantName,
    });
    const formattedValues = prod?.destinationCurrencies.map(currency =>
      listCurrencies.find(option => option?.value === currency)
    );
    const formattedElement = prod?.settlementCurrencies.map(s =>
      listElementCurrencies.find(op => op.value === s)
    );
    const formattedCountries = prod?.destinationCountry.map(c =>
      listCountries.find(Opc => Opc.value === c)
    );
    setCountries(formattedCountries);
    setElementCurrencies(formattedElement);
    setCurrencies(formattedValues);
    setMerchantId(prod.merchantId);
    setSelectedProduct(prod);
    setAddFees(prod.fees);
    setAddLimits(prod.limits);
    setIsEdit(true);
    setaddNew(true);
  };

  const handleOpenDetails = async data => {
    const prod =  await dispatch(getProduct(data.id)).unwrap();
    const listCountries = [];
    const listCurrencies = [];
    const listElementCurrencies = [];
    prod?.destinationCountry.map(m => {
      const countries = {
        label: m,
        value: m,
      };
      return listCountries.push(countries);
    });
    setOpCountries(listCountries);
    prod?.destinationCurrencies.map(m => {
      const currencies = {
        label: m,
        value: m,
      };
      return listCurrencies.push(currencies);
    });
    setOpCurrencies(listCurrencies);
    prod?.settlementCurrencies.map(m => {
      const settlementCurrencies = {
        label: m,
        value: m,
      };
      return listElementCurrencies.push(settlementCurrencies);
    });
    setOpSetElement(listElementCurrencies);

    reset({
      productCode: prod.productCode,
      projectId: prod.projectName,
      settlementCurrencyCode: prod.settlementCurrencyCode,
      description: prod.description,
      merchantName: prod.merchantName,
    });
    setMerchantId(prod.merchantId);
    setSelectedProduct(prod);
    setAddFees(prod.fees);
    setAddLimits(prod.limits);
    setIsView(true);
    setaddNew(true);
  };

  const handleNew = e => {
    setIsEdit(false);
    reset({
      productCode: '',
      description: '',
      projectId: '',
      merchantId: '',
    });
    setAddFees([]);
    setAddLimits([]);
    setaddNew(true);
  };

  const handleCloseNew = e => {
    setaddNew(false);
    setIsView(false);
    setCountries([]);
    setOpCurrencies([]);
    setCurrencies([]);
    setOpCurrencies([]);
    setElementCurrencies([]);
    setOpSetElement([]);
  };

  const handleNewFee = e => {
    setaddNewFee(e);
  };

  const handleNewLimit = e => {
    setAddNewLimit(e);
  };

  const handleEnable = async data => {
    setEnableModalOpen(true);
    setSelectedProduct(data);
  };

  const handleCurrenciesChange = selectedOptions => {
    setCurrencies(selectedOptions);
  };

  const handleElementChange = selectedOptions => {
    setElementCurrencies(selectedOptions);
  };

  const handleChangeCountries = selectedOptions => {
    setCountries(selectedOptions);
  };

  const handleEditDescription = e => {
    setEditDescription(e.target.value);
  };

  const confirmActionProd = async e => {
    if (selectedProduct?.status === 'ENABLED') {
      await dispatch(disableProduct(selectedProduct.id)).unwrap();
    } else {
      await dispatch(enableProduct(selectedProduct.id)).unwrap();
    }
    setEnableModalOpen(false);
    setSelectedProduct('');
    const data = await dispatch(getProducts()).unwrap();
    setProducts(data);
  };

  const handleConfirmEdit = async e => {
    let data = {
      fees: [],
      limits: [],
      description: editDescription,
      destinationCountry: countries,
      destinationCurrencies: currencies,
      settlementCurrencies: elementCurrencies,
      id: selectedProduct.id,
    };
    let resp = await dispatch(updateProduct(data)).unwrap();
    if (resp?.status === 200) {
      toast({
        title: t(`toast.productEditTitle`),
        position: 'top',
        description: t(`toast.productEditDesc`),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      setSelectedProduct('');
      setaddNew(false);
      const data = await dispatch(getProducts()).unwrap();
      setProducts(data);
    }
  };

  return (
    <>
      <Flex direction="row" alignItems="center" textAlign="center">
        <Button
          onClick={handleNew}
          variant="outline"
          boxShadow="0px 8px 15px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1)"
        >
          {t('product.addNew')} +
        </Button>
        <Spacer />
      </Flex>
      <Divider my={6} borderColor={'black'} />
      <Box bgColor={'#eff7f7'} p={6} borderRadius={8}>
        <DataTable
          value={products}
          paginator
          rows={10}
          emptyMessage="no records found"
        >
          {columns.map(col => (
            <Column
              key={col.field}
              field={col.field}
              header={t(`product.${col.field}`)}
              width={col.width}
              sortable={col.sortable}
              body={col.body}
            />
          ))}
        </DataTable>
      </Box>

      <Drawer
        isOpen={addNew}
        size={'xl'}
        placement="right"
        onClose={handleCloseNew}
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={'white'}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {isView
              ? t('product.iconView')
              : !isEdit
              ? t('product.createNew')
              : t('product.iconEdit')}
          </DrawerHeader>

          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                <FormControl isInvalid={errors.productCode}>
                  <FormLabel>{t('product.productCode')}</FormLabel>
                  <Input
                    isDisabled={isEdit || isView}
                    id="productCode"
                    placeholder={t('placeholders.productCode')}
                    {...register('productCode', {
                      required: 'Product Code required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.productCode && errors.productCode.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.description}>
                  <FormLabel>{t('product.description')}</FormLabel>

                  {isEdit && (
                    <Input
                      isDisabled={isView}
                      id="description"
                      placeholder={t('placeholders.description')}
                      value={editDescription}
                      onChange={handleEditDescription}
                    />
                  )}
                  {!isEdit && (
                    <Input
                      isDisabled={isView}
                      id="description"
                      placeholder={t('placeholders.description')}
                      {...register('description', {
                        required: 'Description required',
                      })}
                    />
                  )}
                  <FormErrorMessage>
                    {errors.description && errors.description.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>

              <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                <FormControl isInvalid={errors.projectId}>
                  <FormLabel>{t('product.projectId')}</FormLabel>
                  {!isEdit && !isView && (
                    <SelectTwo
                      isDisabled={isView}
                      id="projectId"
                      placeholder={t('placeholders.option')}
                      {...register('projectId', {
                        required: 'Project is required',
                      })}
                      onChange={handleProject}
                    >
                      {projects
                        ? projects.map(pv => {
                            return (
                              <option value={pv.id}>{pv.description} </option>
                            );
                          })
                        : ''}
                    </SelectTwo>
                  )}

                  {(isView || isEdit) && (
                    <Input
                      id="projectId"
                      {...register('projectId')}
                      isDisabled={isEdit || isView}
                    ></Input>
                  )}
                  <FormErrorMessage>
                    {errors.projectId && errors.projectId.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.merchantId}>
                  <FormLabel>Merchant</FormLabel>
                  {!isEdit && !isView && (
                    <SelectTwo
                      isDisabled={isView}
                      id="merchantId"
                      placeholder={t('placeholders.option')}
                      {...register('merchantId')}
                    >
                      {merchants
                        ? merchants.map(mc => {
                            return <option value={mc.id}>{mc.name} </option>;
                          })
                        : ''}
                    </SelectTwo>
                  )}

                  {(isEdit || isView) && (
                    <Input
                      id="merchantName"
                      {...register('merchantName')}
                      isDisabled={isEdit || isView}
                    ></Input>
                  )}

                  <FormErrorMessage>
                    {errors.merchantId && errors.merchantId.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.settlementCurrencyCode}>
                  <FormLabel>{t('product.settlementCurrency')}</FormLabel>
                  {!isView && (
                    <Select
                      isMulti
                      id="settlementCurrencyCode"
                      options={opSetElement.map(option => ({
                        value: option.value,
                        label: `${option.value} - ${t(
                          `currencyCodes.${option.value}`
                        )}`,
                      }))}
                      value={elementCurrencies}
                      onChange={handleElementChange}
                      placeholder={t('placeholders.option')}
                      closeMenuOnSelect={false}
                      size="sm"
                    />
                  )}

                  {isView && (
                    <Textarea
                      isDisabled={true}
                      value={opSetElement
                        .map(
                          m => `${m.value} - ${t(`currencyCodes.${m.value}`)}`
                        )
                        .join(', ')}
                    ></Textarea>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel>{t('product.countryCode')}</FormLabel>

                  {!isView && (
                    <Select
                      isMulti
                      id="countryCode"
                      options={opCountries.map(option => ({
                        value: option.value,
                        label: `${option.value} - ${t(
                          `countryCodes.${option.value}`
                        )}`,
                      }))}
                      value={countries}
                      onChange={handleChangeCountries}
                      placeholder={t('placeholders.option')}
                      size="sm"
                    />
                  )}

                  {isView && (
                    <Textarea
                      isDisabled={true}
                      value={opCountries
                        .map(
                          m => `${m.value} - ${t(`countryCodes.${m.value}`)}`
                        )
                        .join(', ')}
                    ></Textarea>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>{t('product.currentList')}</FormLabel>

                  {!isView && (
                    <Select
                      isMulti
                      id="currentList"
                      options={opCurrencies.map(option => ({
                        value: option.value,
                        label: `${option.value} - ${t(
                          `currencyCodes.${option.value}`
                        )}`,
                      }))}
                      value={currencies}
                      onChange={handleCurrenciesChange}
                      placeholder={t('placeholders.option')}
                      size="sm"
                    />
                  )}

                  {isView && (
                    <Textarea
                      isDisabled={true}
                      value={opCurrencies
                        .map(
                          m => `${m.value} - ${t(`currencyCodes.${m.value}`)}`
                        )
                        .join(', ')}
                    ></Textarea>
                  )}
                </FormControl>
              </Grid>

              <Flex mt={6} direction={'column'}>
                <Text>{t('product.productFees')}</Text>
                <Divider borderColor={'black'} my={3} />
                {isEdit ||
                  (!isView && (
                    <Flex direction={'row'} justifyContent={'end'}>
                      <Button
                        m={2}
                        onClick={() => handleNewFee(true)}
                        variant="outline"
                        boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                        my={6}
                      >
                        {t('product.newFees')} +
                      </Button>
                    </Flex>
                  ))}

                <Box bgColor={'#eff7f7'} p={6} borderRadius={8}>
                  <DataTable
                    value={addFees}
                    paginator
                    rows={10}
                    emptyMessage="no records found"
                  >
                    {columnsFees.map(col => (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={t(`fees.${col.field}`)}
                        width={col.width}
                        sortable={col.sortable}
                        body={col.body}
                      />
                    ))}
                  </DataTable>
                </Box>
              </Flex>

              <Flex mt={6} direction={'column'}>
                <Text>{t('product.productLimits')}</Text>
                <Divider borderColor={'black'} my={3} />
                {isEdit ||
                  (!isView && (
                    <Flex direction={'row'} justifyContent={'end'}>
                      <Button
                        onClick={() => handleNewLimit(true)}
                        m={2}
                        variant="outline"
                        boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                        my={6}
                      >
                        {t('limits.addNew')} +
                      </Button>
                    </Flex>
                  ))}
                <Box bgColor={'#eff7f7'} p={6} borderRadius={8}>
                  <DataTable
                    value={addLimits}
                    paginator
                    rows={10}
                    emptyMessage="no records found"
                  >
                    {columnsLimits.map(col => (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={t(`limits.${col.field}`)}
                        width={col.width}
                        sortable={col.sortable}
                        body={col.body}
                      />
                    ))}
                  </DataTable>
                </Box>
              </Flex>
              <DrawerFooter>
                <Button
                  cursor="pointer"
                  bgColor={'white'}
                  color={colors.primary}
                  onClick={handleCloseNew}
                  fontSize={15}
                  mx={2}
                  boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                >
                  {t('buttons.cancel')}
                </Button>
                {!isView && !isEdit && (
                  <Button isLoading={isSubmitting} type="submit">
                    {t('buttons.confirm')}
                  </Button>
                )}

                {isEdit && !isView && (
                  <Button onClick={handleConfirmEdit}>
                    {t('buttons.confirmEdit')}
                  </Button>
                )}
              </DrawerFooter>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={addNewFee}
        size={'lg'}
        placement="right"
        onClose={() => handleNewFee(false)}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={'white'}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {t('fees.createNew')}
          </DrawerHeader>

          <DrawerBody>
            <form onSubmit={handleSubmitFormFee(onSubmitFee)}>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
                <FormControl isInvalid={errorsFormFee.description}>
                  <FormLabel>{t('fees.description')}</FormLabel>
                  <Input
                    isDisabled={isEdit}
                    name="description"
                    {...registerFormFee('description', {
                      required: 'Description is required',
                    })}
                    placeholder={t('placeholders.description')}
                  />
                  <FormErrorMessage>
                    {errorsFormFee.description &&
                      errorsFormFee.description.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errorsFormFee.method}>
                  <FormLabel>{t('fees.method')}</FormLabel>
                  <SelectTwo
                    isDisabled={isEdit}
                    {...registerFormFee('method', {
                      required: 'Method is required',
                    })}
                    placeholder={t('placeholders.option')}
                  >
                    <option value="SUM">{t('fees.SUM')}</option>
                    <option value="PERCENTAGE">{t('fees.PERCENTAGE')}</option>
                  </SelectTwo>
                  <FormErrorMessage>
                    {errorsFormFee.method && errorsFormFee.method.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>

              <Divider borderColor={'black'} my={4} />

              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <FormControl isInvalid={errorsFormFee.value}>
                  <FormLabel>{t('fees.value')}</FormLabel>
                  <Input
                    isDisabled={isEdit}
                    name="value"
                    {...registerFormFee('value', {
                      required: 'Value is required',
                    })}
                    placeholder={t('placeholders.value')}
                  />
                  <FormErrorMessage>
                    {errorsFormFee.value && errorsFormFee.value.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errorsFormFee.target}>
                  <FormLabel>{t('fees.target')}</FormLabel>
                  <SelectTwo
                    isDisabled={isEdit}
                    name="target"
                    {...registerFormFee('target', {
                      required: 'Target is required',
                    })}
                    placeholder={t('placeholders.option')}
                  >
                    <option value={'TOTAL'}>{t('fees.TOTAL')}</option>
                    <option value={'EXCHANGE'}>{t('fees.EXCHANGE')}</option>
                  </SelectTwo>
                  <FormErrorMessage>
                    {errorsFormFee.target && errorsFormFee.target.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>

              <Divider borderColor={'black'} my={4} />

              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <FormControl isInvalid={errorsFormFee.priority}>
                  <FormLabel>{t('fees.priority')}</FormLabel>
                  <Input
                    isDisabled={isEdit}
                    name="priority"
                    {...registerFormFee('priority', {
                      required: 'Priority is required',
                    })}
                    placeholder={t('placeholders.priority')}
                  />
                  <FormErrorMessage>
                    {errorsFormFee.priority && errorsFormFee.priority.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errorsFormFee.visible}>
                  <FormLabel>{t('fees.visible')}</FormLabel>
                  <SelectTwo
                    isDisabled={isEdit}
                    name="visible"
                    placeholder={t('placeholders.option')}
                    {...registerFormFee('visible', {
                      required: 'Visible is required',
                    })}
                  >
                    <option value={'true'}>{t('fees.true')}</option>
                    <option value={'false'}>{t('fees.false')}</option>
                  </SelectTwo>
                  <FormErrorMessage>
                    {errorsFormFee.visible && errorsFormFee.visible.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>

              <Divider borderColor={'black'} my={4} />

              <Flex mt={5} direction={'row'} justifyContent={'end'}>
                <Button
                  cursor="pointer"
                  bgColor={'white'}
                  color={colors.primary}
                  onClick={() => handleNewFee(false)}
                  fontSize={15}
                  mx={3}
                  boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button isLoading={isSubmitting} type="submit">
                  {t('buttons.confirm')}
                </Button>
              </Flex>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={addNewLimit}
        size={'lg'}
        placement="right"
        onClose={() => handleNewLimit(false)}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={'white'}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {t('limits.createNew')}
          </DrawerHeader>

          <DrawerBody>
            <form onSubmit={handleSubmitFormLimit(onSubmitLimit)}>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
                <FormControl isInvalid={errorsFormLimit.description}>
                  <FormLabel>{t('limits.description')}</FormLabel>
                  <Input
                    isDisabled={isEdit}
                    id="description"
                    placeholder={t('placeholders.description')}
                    {...registerFormLimit('description', {
                      required: 'Description is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errorsFormLimit.description &&
                      errorsFormLimit.description.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errorsFormLimit.method}>
                  <FormLabel>{t('limits.method')}</FormLabel>
                  <SelectTwo
                    name="method"
                    placeholder={t('placeholders.option')}
                    isDisabled={isEdit}
                    {...registerFormLimit('method', {
                      required: 'Method is required',
                    })}
                  >
                    <option value={'MIN_AMOUNT_PER_TRANSACTION'}>
                      {t('limits.MIN_AMOUNT_PER_TRANSACTION')}
                    </option>
                    <option value={'MAX_AMOUNT_PER_TRANSACTION'}>
                      {t('limits.MAX_AMOUNT_PER_TRANSACTION')}
                    </option>
                    <option value={'MAX_QUANTITY_PER_SENDER'}>
                      {t('limits.MAX_QUANTITY_PER_SENDER')}
                    </option>
                    <option value={'MAX_AMOUNT_PER_SENDER'}>
                      {t('limits.MAX_AMOUNT_PER_SENDER')}
                    </option>
                    <option value={'MAX_QUANTITY_PER_RECIPIENT'}>
                      {t('limits.MAX_QUANTITY_PER_RECIPIENT')}
                    </option>
                    <option value={'MAX_AMOUNT_PER_RECIPIENT'}>
                      {t('limits.MAX_AMOUNT_PER_RECIPIENT')}
                    </option>
                  </SelectTwo>
                  <FormErrorMessage>
                    {errorsFormLimit.method && errorsFormLimit.method.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>

              <Divider borderColor={'black'} my={4} />

              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <FormControl isInvalid={errorsFormLimit.value}>
                  <FormLabel>{t('limits.value')}</FormLabel>
                  <Input
                    id="value"
                    placeholder={t('placeholders.value')}
                    isDisabled={isEdit}
                    {...registerFormLimit('value', {
                      required: 'Value is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errorsFormLimit.value && errorsFormLimit.value.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errorsFormLimit.bufferTime}>
                  <FormLabel>{t('limits.bufferTime')}</FormLabel>
                  <Input
                    isDisabled={isEdit}
                    id="bufferTime"
                    placeholder={t('placeholders.bufferTime')}
                    {...registerFormLimit('bufferTime', {
                      required: 'Buffer Time is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errorsFormLimit.bufferTime &&
                      errorsFormLimit.bufferTime.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>

              <Divider borderColor={'black'} my={4} />

              <Flex mt={5} direction={'row'} justifyContent={'end'}>
                <Button
                  cursor="pointer"
                  bgColor={'white'}
                  color={colors.primary}
                  onClick={() => handleNewLimit(false)}
                  fontSize={15}
                  mx={3}
                  boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button mx={2} isLoading={isSubmitting} type="submit">
                  {t('buttons.confirm')}
                </Button>
              </Flex>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Modal
        isCentered
        isOpen={enableModalOpen}
        onClose={() => setEnableModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent background={'white'}>
          <ModalHeader> {t('modals.infoProd')}</ModalHeader>
          <ModalBody>
            {' '}
            {selectedProduct?.status === 'DISABLED'
              ? t('modals.infoSureEnableProd')
              : t('modals.infoSureDisableProd')}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => setEnableModalOpen(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button
              mx={2}
              colorScheme="blue"
              mr={3}
              onClick={confirmActionProd}
            >
              {t('buttons.confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const Product = () => {
  return (
    <Flex ms={3} flexDirection="column" px={8}>
      <Breadcrumb
        borderRadius={8}
        bg="white"
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
        p={2}
        color="#333"
        my={3}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Acquirer</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">Products</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box bgColor={'white'} p={6} boxShadow={'md'} height={'80vh'}>
        <Flex direction="column" pt={{ base: '120px', md: '5px' }}>
          <TableContainer>
            <DataProcess />
          </TableContainer>
        </Flex>
      </Box>
    </Flex>
  );
};
