import React, { useState } from 'react';
import {
  Flex,
  Box,
  Show,
  Step,
  Stepper,
  StepIndicator,
  StepNumber,
  StepIcon,
  StepTitle,
  StepStatus,
  StepDescription,
  StepSeparator,
} from '@chakra-ui/react';
import { SendType } from './Steps/SendType';
import { FormUnitary } from './Steps/FormUnitary';
import { OverViewUnitary } from './Steps/OverViewUnitary';
import { useTranslation } from 'react-i18next';

export const OctUnitary = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [sendTypeData, setSendTypeData] = useState('');
  const [form, setForm] = useState('');
  const [steps, setSteps] = useState([
    {
      title: 'stepOne',
      description: 'oneDesc',
    },
    { title: 'stepTwo', description: 'twoDesc' },
    { title: 'stepThree', description: 'threeDesc' },
  ]);

  const handleStep = e => {
    let i = step + 1;
    if (e !== undefined) {
      setStep(i);
      setForm(e);
    }
    setStep(i);
  };

  const handleBackStep = e => {
    let i = step - 1;
    setStep(i);
  };

  const handleSendTypeData = data => {
    setSendTypeData(data);
  };

  const handleRestartStep = e => {
    setStep(0);
  };

  return (
    <>
      <Flex
        direction="column"
        justifyContent="start"
        alignItems="center"
        alignContent={'start'}
        display={'flex'}
        minHeight={'90vh'}
      >
        <Show breakpoint="(min-width: 450px)">
          <Box
            bgColor={'white'}
            borderColor={'#7e7477'}
            borderRadius={10}
            mt={10}
            py={5}
            minW={'63vw'}
            boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
          >
            <Stepper colorScheme="red" index={step} m={6}>
              {steps.map(step => (
                <>
                  <Step key={step.description}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon color={'white'} />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle> {t(`steps.${step.title}`)}</StepTitle>
                      <StepDescription>
                        {t(`steps.${step.description}`)}
                      </StepDescription>
                    </Box>
                    <StepSeparator ms={3} />
                  </Step>
                </>
              ))}
            </Stepper>
          </Box>
        </Show>
        {step === 0 && (
          <Box m={6} bgColor={'white'} p={6} boxShadow={'md'} borderRadius={8}>
            <SendType
              sendDataToParent={handleSendTypeData}
              nextStep={handleStep}
            />
          </Box>
        )}

        {step === 1 && (
          <Box m={6} bgColor={'white'} p={6} boxShadow={'md'} borderRadius={8}>
            <FormUnitary nextStep={handleStep} backStep={handleBackStep} />
          </Box>
        )}

        {step === 2 && (
          <OverViewUnitary
            restartStep={handleRestartStep}
            backStep={handleBackStep}
            values={form}
          />
        )}
      </Flex>
    </>
  );
};
